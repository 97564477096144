@import "../../../_theme_variables";

.gallery-thumb-list li a img {
	background-color:#f8f8f8;
	border:none;
	@include border-radius(0);
}
.gallery-thumb-list li.active a img {
	-webkit-box-shadow: inset 0 0 0 3px #ddd;
	-moz-box-shadow: inset 0 0 0 3px #ddd;
	box-shadow: inset 0 0 0 3px #ddd;
}